import { CrossFilterTypes } from '@/modules/cross-filters/constants/cross-filters.constants';
import { CrossFilterType } from '@/modules/cross-filters/types/cross-filter.types';

type CrossFilterTranslations = {
  cross_filters: Record<string, unknown> & { types: Record<CrossFilterType, { title: string }> };
};

export const en: CrossFilterTranslations = {
  cross_filters: {
    titles: {
      cross_filters_v2: 'Dynamic Cross Filters',
    },
    labels: {
      flight: 'Flight',
      cabin: 'Cabin',
    },
    types: {
      // TODO: https://dev.azure.com/kambr/Eddy/_workitems/edit/19108
      // [CrossFilterTypes.BOOKINGS]: {
      //   title: 'Bookings',
      // },
      // TODO: https://dev.azure.com/kambr/Eddy/_workitems/edit/19130
      // [CrossFilterTypes.DAY_OF_WEEK]: {
      //   title: 'Day of Week',
      // },
      [CrossFilterTypes.ALLOCATION_AMOUNT]: {
        title: 'Allocation Amount',
      },
      [CrossFilterTypes.BOOKING_AMOUNT]: {
        title: 'Booking Amount',
      },
      // TODO: https://dev.azure.com/kambr/Eddy/_workitems/edit/19237
      // [CrossFilterTypes.SEATS_AVAILABLE]: {
      //   title: 'Seats Available',
      // },
      [CrossFilterTypes.MATERIALIZATION_RATE]: {
        title: 'Materialization Rate',
      },
      [CrossFilterTypes.LAF_OFFSET]: {
        title: 'LAF Offset',
      },
      [CrossFilterTypes.CALCULATED_CLASS]: {
        title: 'Calculated Class',
      },
      [CrossFilterTypes.SOURCE_LAF_FARE_CODE]: {
        title: 'Source LAF Fare Code',
      },
    },
    info: {
      invalid_selected_path:
        "If no options are present, it's very likely you'd need to change the control settings, e.g. select tour operators.",
    },
    errors: {
      invalid_selected_path: 'Selected cross filter path is not valid. Change it using the cascader in the top right of this cross filter.',
    },
  },
};
