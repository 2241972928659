import { CrossFilterQueryValue } from '@/modules/cross-filters/composables/use-cross-filter.composable';
import { CrossFilterType } from '@/modules/cross-filters/types/cross-filter.types';
import { CustomerSettingsModel } from '@/modules/customer-settings/api/customer-settings.contracts';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';

export const CrossFilterTypes = {
  // TODO: https://dev.azure.com/kambr/Eddy/_workitems/edit/19108
  // BOOKINGS: 'bookings',
  // TODO: https://dev.azure.com/kambr/Eddy/_workitems/edit/19130
  // DAY_OF_WEEK: 'day_of_week',
  ALLOCATION_AMOUNT: 'allocation_amount',
  BOOKING_AMOUNT: 'booking_amount',
  // TODO: https://dev.azure.com/kambr/Eddy/_workitems/edit/19237
  // SEATS_AVAILABLE: 'seats_available',
  MATERIALIZATION_RATE: 'materialization_rate',
  LAF_OFFSET: 'laf_offset',
  CALCULATED_CLASS: 'calculated_class',
  SOURCE_LAF_FARE_CODE: 'source_laf_fare_code',
} as const;

export const CrossFilterTypesSet: Set<CrossFilterType> = new Set(Object.values(CrossFilterTypes));

const SingleInventoryManagementCrossFilterTypes: Set<CrossFilterType> = new Set([
  'allocation_amount',
  'booking_amount',
  // TODO: https://dev.azure.com/kambr/Eddy/_workitems/edit/19237
  // 'seats_available',
  'materialization_rate',
  'laf_offset',
  'calculated_class',
  'source_laf_fare_code',
]);

export type DynamicCrossFilterOptionFilterParams = { customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities> | undefined };

export type DynamicCrossFilterOption = {
  type: CrossFilterType;
  filter?: ((opts: DynamicCrossFilterOptionFilterParams) => boolean) | boolean;
};

export const CrossFilterOptionsConfiguration: Record<CrossFilterType, DynamicCrossFilterOption> = {
  ...Object.values(CrossFilterTypes).reduce(
    (acc, type) => {
      acc[type] = {
        type,
        ...(SingleInventoryManagementCrossFilterTypes.has(type)
          ? { filter: ({ customerSettings }) => !!customerSettings?.hasSingleInventoryManagementEnabled }
          : {}),
      };
      return acc;
    },
    {} as Record<CrossFilterType, DynamicCrossFilterOption>,
  ),
};

export const ControlCrossFilterDefaults: CrossFilterQueryValue[] = [];
